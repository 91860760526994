import { ReactComponent as CallIcon } from "../../assets/images/call.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";
import './CallActionsStyles.scss';
import { CSSProperties } from "react";
import { FormattedMessage } from "react-intl";

const CallActions = ({ style }: { style?: CSSProperties }) => {
    return <div className="cta-buttons call-actions-container" style={style}>
        <a href="https://forms.gle/Ncizfpjxn85kw3xa7" target="_blank" rel="noreferrer" className="btn btn-light btn-lg" >
            <FormattedMessage id="call_actions.link_form" />
            <ArrowRight />
        </a>
        <a href="tel:+91-9834903935" className="btn btn-lg btn-outline-light custom-call-btn">
            <span className="call-btn-label"><FormattedMessage id="call_actions.call_mobile" /></span>
            <CallIcon className="call-icon" />
        </a>
    </div>
}

export default CallActions;