
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Announce } from "../../../assets/images/icons/Steps/announce.svg";
import { ReactComponent as Indication } from "../../../assets/images/icons/Steps/indication.svg";
import { ReactComponent as Money } from "../../../assets/images/icons/Steps/money.svg";
import { ReactComponent as People } from "../../../assets/images/icons/Steps/people.svg";
import { ReactComponent as Download } from "../../../assets/images/icons/Feature/download.svg";
import { ReactComponent as Statics } from "../../../assets/images/icons/Feature/statics.svg";
import { ReactComponent as BookingList } from "../../../assets/images/icons/Feature/booking-list.svg";
import { ReactComponent as Time } from "../../../assets/images/icons/Feature/time.svg";
import SampleImage from '../../../assets/images/icons/Feature/sample-image-features.png'
import SampleImageHindi from '../../../assets/images/icons/Feature/sample-image-features_hindi.png'
import "./styles.scss";
import { useCommonContext } from "../../../commonContextProvider";

export const FeaturesData = [
  {
    title: "features.title.1",
    img: <People />,
  },
  {
    title: "features.title.2",
    img: <Money />,
  },
  {
    title: "features.title.3",
    img: <Indication />,
  },
  {
    title: "features.title.4",
    img: <Announce />,
  },
];

export const benefitsIcon = [
  {
    img: <Download />,
  },
  {
    img: <Statics />,
  },
  {
    img: <BookingList />,
  },
  {
    img: <Time />,
  },
]

const Features = () => {
  const { lang } = useCommonContext();
  const intl = useIntl();
  const messages: any = intl.messages[`home.slider.item.0.subtitle_list`];
  const featureHeading: any = intl.messages[`features.heading`];
  const benefits: any = intl.messages['benefits'];
  return (
    <div id="features" className="feature-scroll" >
      <div className="container" data-scroll-index="1">
        <div className="d-flex align-items-center justify-content-between">
          <div className="reaches-heading">
            {featureHeading?.map?.((heading: string) => <div key={heading}>{heading}</div>)}
          </div>
        </div>
        <div className="row features-row">
          {FeaturesData.map?.((item, index) => (
            <div className="col-md-6 col-lg-3 col-sm-12 features-col" key={index}>
              <div className="features-icon">{item.img}</div>
              <div className="features-text">
                <div className="features-title"><FormattedMessage id={item.title} /></div>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-between feature-container">
          <div className="d-flex flex-column justify-content-between benefits-container" >
            <p className="benefits-heading"><FormattedMessage id="benefits.heading" /></p>
            <div className="custom-features-items-wrapper">
              {benefitsIcon.map((item: any, index: any) => (
                <div className="d-flex align-items-center my-5" key={index}>
                  <div>{item.img}</div>
                  <div className="feature-description-wrapper">
                    <div className="benefit-title" >{benefits[index]?.title}</div>
                    <div className="benefit-description">{benefits[index]['description.1']}</div>
                    <div className="benefit-description">{benefits[index]['description.2']}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img className="w-50 feature-sample-img" src={lang === 'hi' ? SampleImageHindi : SampleImage} alt="sample" />
        </div>

        <div className="info-container">
          <p className="info-container_heading"><FormattedMessage id={`home.slider.item.0.title`} /></p>
          <p className="info-container_description"><FormattedMessage id={`home.slider.item.0.subtitle`} /></p>
          <ul>
            {messages?.map?.((ele: string) => <li key={ele}>{ele}</li>)}
          </ul>
          <p style={{ textAlign: 'center' }}><a className="info-container_link"><FormattedMessage id={`home.slider.link`} /></a> </p>
        </div>
      </div>
    </div>
  );
};
export default Features;
