import { useRef, useState } from "react";
import Slider from "react-slick";
import cx from "classnames";
import Poster1 from "../../../assets/images/hero/slider-img-1.png";
import Poster1Hindi from "../../../assets/images/hero/slider-img-1_hindi.png";
import Poster2 from '../../../assets/images/hero/slider-img-2.png';
import Poster2Hindi from '../../../assets/images/hero/slider-img-2_hindi.png';

import { useIntl } from "react-intl";

import "./sliders.scss";
import { FormattedMessage } from "react-intl";
import { useCommonContext } from "../../../commonContextProvider";

export const benefitList = [
  {
    id: 0,
    icon_en: Poster1,
    icon_hi: Poster1Hindi,
  },
  {
    id: 1,
    icon_en: Poster2,
    icon_hi: Poster2Hindi,
  }];

const BenefitsSlider = () => {
  const { lang } = useCommonContext();
  const sliderRef = useRef<any>(null);
  const intl = useIntl();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    afterChange: (current: number) => setActiveIndex(current),
  };
  const handleSlider = (index: number) => {
    sliderRef.current.slickGoTo(index);
    setActiveIndex(index);
  };
  return (
    <div className="text-center container benefitsSlider mt-8 custom-slider-style">
      <Slider ref={sliderRef} {...settings}>
        {benefitList.map((item: any, index) => {
          const messages: any = intl.messages[`home.slider.item.${index}.subtitle_list`];
          return (
            <div className="sliderContent" key={item.id}>
              <div className="bSliderImage">
                <img className='bImage' src={item[`icon_${lang}`]} alt="poster" />
              </div>
              <div className='bContent'>
                <div className="home-slider-header"><FormattedMessage id={`home.slider.header`} /></div>
                <h1 className='bgTitle text-start fw-bold mt-5 mx-2'><FormattedMessage id={`home.slider.item.${index}.title`} /></h1>
                <p className='dMsg text-start mt-5 mx-2'><FormattedMessage id={`home.slider.item.${index}.subtitle`} /></p>
                <ul>
                  {messages?.map?.((ele: string) => <li key={ele} >{ele}</li>)}
                </ul>
                <a className="slider-link" href="https://forms.gle/Ncizfpjxn85kw3xa7" target="_blank" rel="noreferrer" ><FormattedMessage id={`home.slider.link`} /></a>
              </div>
            </div>
          )
        })}
      </Slider>
      <ul className='dots'>
        {benefitList.map((item: any) => <li key={item.id} className={cx({ 'active': activeIndex === item.id })} onClick={() => handleSlider(item.id)} ></li>)}
      </ul>
    </div>
  );
};

export default BenefitsSlider;
