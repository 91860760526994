import cx from "classnames";
import { Link } from "react-router-dom";
import LanguageIcon from "../../assets/images/language.svg";
import MenuIcon from "../../assets/images/menu.png";
import { useCommonContext } from "../../commonContextProvider";
import { LINKS } from "./constant";
import "./styles.scss";
import useNavbar from "./useNavbar";
import { useState } from "react";
import CloseIcon from '../../assets/images/close_icon.png'
import { FormattedMessage } from "react-intl";
import WatchVideo from '../../assets/images/icons/watch-video.svg';
import WatchVideoMob from '../../assets/images/icons/watch-video-mob.png'
import { EnglishLogo, HindiLogo } from "./LogoIcon";

const videoLink = "https://common-assets-web.s3.ap-south-1.amazonaws.com/broker_app_demo_v2.mp4";

const Navbar = () => {
  const { toggleLanguage, lang } = useCommonContext();
  const { toggle, setToggle, scroll } = useNavbar();
  const [showHamburger, setShowHamburger] = useState(false);
  const getLogo:  { [key: string]: any }  = {
    hi: <HindiLogo />,
    en: <EnglishLogo /> 
  }
  return (
    <nav className={cx("navbar navbar-expand-md", { "box-shadow": scroll })}>
      <div className="container">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Link to="/" className="nav-link logo-title" onClick={() => window.scrollTo(0, 0)}>
              <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                {getLogo[lang]}
              </div>
            </Link>
            <div className="d-inline-flex align-items-center" style={{ gap: '30px' }}>
              <a className="watch-video-link" href={videoLink} target="_blank">
                <FormattedMessage id="watch.video" />
                <img src={WatchVideo} alt="video-icon" className="watch-video-icon" />
              </a>
              <img
                width="20px"
                height="20px"
                src={LanguageIcon}
                alt="language_icon"
                className="language-icon"
                onClick={toggleLanguage}
              />
              <div className={`nav-menu-container ${showHamburger ? '' : 'hide-menu'}`}>
                <img src={CloseIcon} className='close-hamburger' alt="close icon" onClick={() =>  setShowHamburger(false)} />
                <div className="nav-list-items">
                  {LINKS.map((item, index) => {
                    return <Link
                      key={item.label}
                      className="nav-link"
                      to="/"
                      data-scroll-nav={item.scrollId}
                      style={{width: item.width}}
                      onClick={() => {setToggle(!toggle); setShowHamburger(false)}}
                    >
                      <FormattedMessage id={`header.nav.item_${index}`} />
                    </Link>
                  })}
                  <a className="watch-video-link for-mob" href={videoLink} target="_blank" onClick={()=> setShowHamburger(false)}>
                    <FormattedMessage id="watch.video" />
                    <img src={WatchVideoMob} alt="video-icon" className="watch-video-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <img
            src={MenuIcon}
            alt="hamburger_icon"
            className="hamburger_icon"
            onClick={() => { setShowHamburger(true); console.log('hello') }}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
