import { FormattedMessage } from "react-intl";
import SampleAppViewPoster from "../../../assets/images/hero/sample_app_view.png";
import SampleAppViewPosterHindi from "../../../assets/images/hero/sample_app_view_hindi.png";
import "./styles.scss";
import { CallActions } from "../../Common";
import { useCommonContext } from "../../../commonContextProvider";

const Hero = () => {
  const { lang } = useCommonContext();
  return (
    <div className="hero-container text-center align-items-center d-flex" id="top" data-scroll-index="0">
      <div className="container d-flex w-100 justify-content-between align-items-center" >
        <div className="left-section">
          <div className="hero-heading">
            <div >
              <h1>
                <FormattedMessage id="section.hero.title" />
              </h1>
            </div>
            <div>
              <FormattedMessage id="section.hero.subtitle" />
            </div>
          </div>
          <CallActions />
        </div>
        <div>
          <img
            height="484px"
            src={lang === 'en' ? SampleAppViewPoster : SampleAppViewPosterHindi}
            alt="Sample App View"
            className="sample-poster-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
