import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { IntlProvider } from "react-intl";
import { Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.scss";
import { useCommonContext } from "./commonContextProvider";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LocaleMessages from "./lang";


function App() {
  const { lang } = useCommonContext();

  return (
    <IntlProvider locale={lang} messages={LocaleMessages[lang]}>
      <Navbar />
      <div className="overflow-hidden" style={{ position: 'relative', top: '72px' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </IntlProvider>
  );
}

export default App;
