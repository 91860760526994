import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { CallActions } from "../Common";
import { ReactComponent as Logo } from "../../assets/images/icons/Logo.svg";

import "./styles.scss";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container d-flex justify-content-between align-items-center h-100" style={{ gap: '40px' }}>
        <div className="d-flex footer-info-container">
          <div className="privacy-wrapper">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <Logo />
            </Link>
            <Link to='/privacy-policy' className="privacy-policy" >
              <FormattedMessage id="privacy.policy.heading" />
            </Link>
          </div>
          <div className="copyright d-lg-block"><FormattedMessage id="footer.copyright" /></div>
        </div>
        <div className="footer-actions-wrapper">
          <CallActions style={{ display: 'flex', flexDirection: 'column' }} />
        </div>
      </div>
    </div>
  );
};
export default Footer;
