import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, PolicyList } from './constants';
import './styles.scss';
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy container-fluid">
      <div className="heading">{Heading}</div>
      <div className="list row justify-content-center">
        <div className="col-xl-8">
          <span>
            <FormattedMessage id="privacy.policy.first.para" />
          </span>
          <ol>
            {PolicyList.map((item, index) => (
              <div key={index}>
                <li className="title">{item.title}</li>
                {item.txt}
                <ol>
                  {item.Points &&
                    item.Points().map((p: any, index: number) => (
                      <li>{p}</li>
                  ))}
                </ol>
              </div>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
