import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';


export const CommonContext = React.createContext({
    toggleLanguage: () => { },
    lang: ''
});
const CommonContextProvider = ({ children }: { children?: ReactNode }) => {
    const [lang, setLang] = useState('hi');

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams.get('lang')) {
            setLang(searchParams.get('lang') === 'hi' ? 'hi' : 'en' )
        }
    }, [searchParams]);

    const toggleLanguage = useCallback(() => {
        const enTitle = "Broker App | Your own app for all your requirements!";
        const hiTitle = "ब्रोकर ऐप । आपकी हर जरूरत के लिए, आपकी अपनी ऐप!"
        setLang(lang === 'hi' ? 'en' : 'hi');
        document.title = lang === 'hi' ?  enTitle :  hiTitle;
    }, [lang]);

    const value: any = {
        toggleLanguage,
        lang
    };
    return (
        <CommonContext.Provider value={value} >
            {children}
        </CommonContext.Provider>
    );
};

export const useCommonContext = () => {
    return useContext(CommonContext);
}

export default CommonContextProvider;