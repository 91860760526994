import { useEffect } from "react";
import Footer from "../Footer";
import FooterBanner from "../FooterBanner";
import Features from "./Features";
import Hero from "./Hero";
import BenefitsSlider from "./Slider/Slider";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
      <Features />
      <BenefitsSlider />
      <FooterBanner />
      <Footer />
    </>
  );
};
export default Home;
