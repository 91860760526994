import { useIntl } from 'react-intl';
import { useCommonContext } from '../../commonContextProvider';
import useWindowSize from '../../resizeHook';
import BannerPosterMob from '../../assets/images/footer-banner-mob.png';
import BannerPosterMobHindi from '../../assets/images/footer-banner-mob_hindi.png';
import BannerPoster from "../../assets/images/footer-banner.png";
import BannerPosterHindi from "../../assets/images/footer-banner_hindi.png";

const FooterBanner = () => {
  const { width } = useWindowSize();
  const { lang } = useCommonContext();
  const intl = useIntl();
  const featureHeading: any = intl.messages['footer.banner.title'];
  const featureHeadingPrice: any = intl.messages['footer.banner.title.price'];

  return (
    <div className="footer-banner-container" >
      <div className="container justify-content-between align-items-center custom-footer-banner">
        <div className="banner-title">
          {featureHeading.map((msg: any) => (
            <span style={{ display: 'block'}} key={msg} >{msg}</span>
          ))}
          <span style={{ display: 'block', fontWeight: 700 }}>{featureHeadingPrice}</span>
        </div>
        <img src={lang === 'hi' ? (width > 992 ? BannerPosterHindi : BannerPosterMobHindi) : (width > 992 ? BannerPoster : BannerPosterMob)} className={width > 992 ? '' : 'banner-poster'} alt="banner poster" height="100%" />
      </div>
    </div>
  )
}
export default FooterBanner;