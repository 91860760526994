import { FormattedMessage, useIntl } from "react-intl";

export const Heading = <FormattedMessage id="privacy.policy.heading" />;
export const PolicyList = [
  {
    title: <FormattedMessage id="privacy.policy.collection.heading" />,
    Points: () => {
      const intl = useIntl();
      const collectionData: any = intl.messages['privacy.policy.collection.data'];
      return collectionData.map((data: any) => <div>{data}</div>);
    },
  },
  {
    title: <FormattedMessage id="privacy.policy.app.permission.heading" />,
    Points: () => {
      const intl = useIntl();
      const disclosureData: any = intl.messages['privacy.policy.app.permission.data'];
      const disclosureLocation: any = intl.messages['privacy.policy.app.permission.data0'];
      const disclosureCamera: any = intl.messages['privacy.policy.app.permission.data1'];
      const disclosureMedia: any = intl.messages['privacy.policy.app.permission.data2'];
      const disclosureContacts: any = intl.messages['privacy.policy.app.permission.data3'];
      return disclosureData.map((data: any, index: number) => {
        if (index === 0) {
          return (
            <data>
              {data}
              <ol key={`disclosure-${index}`} type="a">
                {disclosureLocation.map((data: any, index: number) => (
                  <li className="li_text" key={index}>
                    {data}
                  </li>
                ))}
              </ol>
            </data>
          );
        }else if(index === 1){
          return (
            <data>
              {data}
              <ol key={`disclosure-${index}`} type="a">
                {disclosureCamera.map((data: any, index: number) => (
                  <li className="li_text" key={index}>
                    {data}
                  </li>
                ))}
              </ol>
            </data>
          );
        }else if(index === 2){
          return (
            <data>
              {data}
              <ol key={`disclosure-${index}`} type="a">
                {disclosureMedia.map((data: any, index: number) => (
                  <li className="li_text" key={index}>
                    {data}
                  </li>
                ))}
              </ol>
            </data>
          );
        }else if(index === 3){
          return (
            <data>
              {data}
              <ol key={`disclosure-${index}`} type="a">
                {disclosureContacts.map((data: any, index: number) => (
                  <li className="li_text" key={index}>
                    {data}
                  </li>
                ))}
              </ol>
            </data>
          );
        }
        return <div>{data}</div>;
      });
    },
  },
  {
    title: <FormattedMessage id="privacy.policy.disclosure.heading" />,
    Points: () => {
      const intl = useIntl();
      const disclosureData: any = intl.messages['privacy.policy.disclosure.data'];
      const disclosureInnerData: any = intl.messages['privacy.policy.disclosure.data0'];
      return disclosureData.map((data: any, index: number) => {
        if (index === 0) {
          return (
            <data>
              {data}
              <ol key={`disclosure-${index}`} type="a">
                {disclosureInnerData.map((data: any, index: number) => (
                  <li className="li_text" key={index}>
                    {data}
                  </li>
                ))}
              </ol>
            </data>
          );
        }
        return <div>{data}</div>;
      });
    },
  },
  {
    title: <FormattedMessage id="privacy.policy.personal.info.security.heading" />,
    txt: (
      <div>
        <FormattedMessage id="privacy.policy.personal.info.security.data" />
      </div>
    ),
  },
  {
    title: <FormattedMessage id="privacy.policy.personal.info.access.heading" />,
    txt: (
      <div>
        <FormattedMessage id="privacy.policy.personal.info.access.data" />
      </div>
    ),
  },
  {
    title: <FormattedMessage id="privacy.policy.data.protection.heading" />,
    txt: (
      <div>
        <FormattedMessage id="privacy.policy.data.protection.data" />
      </div>
    ),
  },
  {
    title: <FormattedMessage id="privacy.policy.iso.heading" />,
    txt: (
      <div>
        <FormattedMessage id="privacy.policy.iso.data" />
      </div>
    ),
  },
  {
    title: <FormattedMessage id="privacy.policy.term.heading" />,
    txt: (
      <div>
        <FormattedMessage id="privacy.policy.term.data" />
      </div>
    ),
  },
  {
    title: <FormattedMessage id="privacy.policy.general.term.heading" />,
    txt: (
      <div>
        <FormattedMessage id="privacy.policy.general.term.data" />
      </div>
    ),
  },
  {
    title: <FormattedMessage id="privacy.policy.changes.heading" />,
    txt: (
      <div>
        <FormattedMessage id="privacy.policy.changes.data" />
      </div>
    ),
  },
];
