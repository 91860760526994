export const LINKS = [
  {
    to: 'home',
    label: 'Home',
    scrollId: '0',
    width: '75px'
  },
  {
    to: 'product',
    label: 'Product',
    scrollId: '1',
    width: '95px'
  },
  {
    to: 'contact',
    label: 'Contact Us',
    scrollId: '0',
    width: '115px'
  }
];